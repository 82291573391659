// UserChat.jsx
import React, { useEffect, useState, useRef } from "react";
import localforage from "localforage";
import {
  Input,
  Button,
  Tooltip,
  Space,
  Upload,
  Avatar,
  Empty,
  Tag,
  Typography,
  Spin,
  Switch,
} from "@arco-design/web-react";
import { IconFolderAdd, IconSend } from "@arco-design/web-react/icon";
import { getGroupMemberHeadApi, sendMessageApi, setAutoReplyForUser } from "../../../api/normalApi";
import { modifyMessageReadApi } from "../../../api/openaiAPI";
import { formatTimestampToDateTime } from "../../../utils/format";

const { Text } = Typography;

const UserChat = ({
  currentWxUser,
  currentWxMyAccount,
  messages,
  setMessages,
  imageUrls,
  getMessageList,
  assistant,
  autoReply,
  setAutoReply,
}) => {
  const [inputValue, setInputValue] = useState("");
  const contentRef = useRef(null);
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const [messageList, setMessageList] = useState([]); //展示当前的消息列表（增加了头像）

  // 滚动事件处理
  const handleScroll = () => {
    setIsUserScrolling(true);
  };
  useEffect(() => {
    // 添加滚动事件监听
    const contentElement = contentRef.current;
    if (contentElement) {
      contentElement.addEventListener("scroll", handleScroll);
    }

    // 清理事件监听
    return () => {
      if (contentElement) {
        contentElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (contentRef.current && !isUserScrolling) {
      contentRef.current.scrollTo({
        top: contentRef.current.scrollHeight,
        behavior: "smooth",
      });
    }

    if (messages && messages.length > 0) {
      modifyMessageRead();
    }

    if (messages.length !== messageList.length) {
      // 当前消息有更新
      setMessageList(messages);
      getUserToIdAndNickname(messages);
      setIsUserScrolling(false);
    }
    // eslint-disable-next-line
  }, [messages, isUserScrolling]);

  // 获取每条消息的头像
  const getUserToIdAndNickname = async (messages) => {
    const wxUserId = currentWxMyAccount.wx_user_id;
    const cacheKey = `${wxUserId}-headImgList`;
    const nicknameKey = `${wxUserId}-nicknameList`;

    // 从 localForage 获取缓存数据，直接作为对象使用
    const cachedHeadImgList = (await localforage.getItem(cacheKey)) || {};
    const cachedNicknameList = (await localforage.getItem(nicknameKey)) || {};

    // 创建一个 Map 以 actual_user_id 为 key 进行分组
    const userIdMap = new Map();
    messages.forEach((message, index) => {
      const userId = message.metadata?.actual_user_id;
      if (userId) {
        if (!userIdMap.has(userId)) {
          userIdMap.set(userId, []);
        }
        userIdMap.get(userId).push({ message, index });

        // 更新昵称缓存
        const nickname = message.metadata?.actual_nickname;
        if (nickname) {
          cachedNicknameList[userId] = nickname; // 将昵称存入缓存
        }
      }
    });

    // 如果昵称缓存有数据，则存储到 localForage
    if (Object.keys(cachedNicknameList).length > 0) {
      await localforage.setItem(nicknameKey, cachedNicknameList);
    }

    // 遍历每个唯一的 actual_user_id，检查缓存或请求数据
    for (const [userId, messageGroup] of userIdMap.entries()) {
      let head_img = cachedHeadImgList[userId];

      // 如果缓存中没有该用户的头像，则调用接口请求
      if (!head_img) {
        try {
          head_img = await getGroupMemberHead(userId);
          // 更新缓存
          cachedHeadImgList[userId] = head_img;
        } catch (error) {
          console.log(`Error fetching head_img for userId ${userId}:`, error);
          head_img = null;
        }
      }

      // 如果头像缓存有数据，则存储到 localForage
      if (Object.keys(cachedHeadImgList).length > 0) {
        await localforage.setItem(cacheKey, cachedHeadImgList);
      }

      // 更新所有关联消息的 head_img 和 nickname
      setMessageList((prevMessages) =>
        prevMessages.map((msg, idx) => {
          const match = messageGroup.find((m) => m.index === idx);
          return match
            ? {
              ...msg,
              head_img: head_img || null, // 如果没有头像则设置为 null
              nickname: match.message.metadata.actual_nickname,
            }
            : msg;
        }),
      );
    }
  };

  // 返回单条消息的头像
  const getGroupMemberHead = async (actual_user_id) => {
    try {
      const response = await getGroupMemberHeadApi(
        currentWxMyAccount.wx_user_id,
        currentWxUser.to_user_id,
        actual_user_id,
      );
      return response.data.head_img; // 返回头像数据
    } catch (err) {
      console.log(err);
      return null; // 若请求失败，返回 null
    }
  };

  const modifyMessageRead = async () => {
    const latestMessage = messages.find(
      (item) => item.thread_id === currentWxUser.thread_id,
    );
    if (!latestMessage) return;

    const data = {
      metadata: {
        readTime: String(Math.floor(Date.now() / 1000)),
      },
    };
    try {
      await modifyMessageReadApi(
        latestMessage.thread_id,
        latestMessage.id,
        data,
      );
      // Optionally handle the response
    } catch (error) {
      console.error("Error marking messages as read:", error);
    }
  };

  const handleSend = async () => {
    if (!inputValue.trim()) return;

    const back_data = {
      wx_user_id: currentWxMyAccount.wx_user_id,
      to_user_id: currentWxUser.to_user_id, // Removed `.current`
      msg: inputValue,
    };
    setInputValue("");
    try {
      const response = await sendMessageApi(back_data);
      if (response.status === 200) {
        getMessageList(currentWxUser.thread_id).then((data) => {
          setMessages(data.data);
        });
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleAutoReplyToggle = (checked) => {
    const back_data = {
      wx_user_id: currentWxMyAccount.wx_user_id,
      nickname: currentWxUser.nickname,
      auto_reply: checked ? "true" : "false"
    };

    setAutoReplyForUser(back_data).then((res) => {
      if (res.status === 200) {
        setAutoReply(checked);
      }
    });
  };

  if (!currentWxUser) {
    return (
      <Space
        direction="vertical"
        style={{
          height: "92vh",
          boxShadow: "0px 0 6px rgba(0, 0, 0, 0.08)",
          marginLeft: 5,
        }}
        align={"start"}
      >
        <Empty
          description="请选择一个用户进行聊天"
          style={{ marginTop: "40.8vh", width: "39.5vw" }}
        />
      </Space>
    );
  }

  return (
    <Space
      direction="vertical"
      style={{
        height: "92vh",
        // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.08)",
        marginLeft: 5,
      }}
      align={"start"}
    >
      <Space
        direction="horizontal"
        style={{
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Avatar shape="square" style={{ backgroundColor: "#3370ff" }} size={33}>
          <img
            alt="avatar"
            src={`data:image/png;base64,${currentWxUser.head_img}`}
          />
        </Avatar>
        <h3 style={{ marginTop: 0, marginBottom: 0 }}>
          {currentWxUser.nickname || ""}
        </h3>

        <Space>
          <Tag color={assistant !== null && assistant.name ? "green" : "red"}>
            {assistant !== null && assistant.name ? "正在服务" : "暂停服务"}
          </Tag>

          <h3 style={{ marginTop: 0, marginBottom: 0, marginLeft: 5 }}>
            {(assistant !== null && assistant.name) || "暂未分配数字员工"}
          </h3>
          <Switch
            checked={autoReply}
            onChange={handleAutoReplyToggle}
            checkedText="开启"
            uncheckedText="关闭"
          />
        </Space>
      </Space>
      <Space
        direction="vertical"
        style={{
          background: "#f7f7fa",
          height: "77vh",
          width: "38vw",
          overflowY: "auto",
          scrollbarWidth: "thin",
          paddingLeft: 15,
          paddingTop: 10,
          paddingBottom: 10,
          scrollbarColor: "#EEE white",
          boxShadow: "0 2px 2px rgba(0, 0, 0, 0.03)",
        }}
        ref={contentRef}
      >
        {messages.length === 0 ? (
          <Space
            align="center"
            style={{ width: "100%", height: "65vh", justifyContent: "center" }}
            direction={"vertical"}
          >
            <Spin dot />
          </Space>
        ) : (
          messages
            .slice()
            .reverse()
            .map((msg, index) => {
              return (
                <Space key={msg.id || index} align={"start"}>
                  <Avatar shape="square" style={{ backgroundColor: "#3370ff" }}>
                    {msg.role === "user" ? (
                      currentWxUser.is_group ? (
                        // 群聊用户头像
                        messageList[messages.length - 1 - index] &&
                          messageList[messages.length - 1 - index].head_img ? (
                          <img
                            alt="avatar"
                            src={`data:image/png;base64,${messageList[messages.length - 1 - index].head_img}`}
                          />
                        ) : messageList[messages.length - 1 - index] ? (
                          messageList[messages.length - 1 - index].nickname
                        ) : (
                          currentWxUser.nickname.charAt(0)
                        )
                      ) : // 私聊用户头像
                        currentWxUser.head_img ? (
                          <img
                            alt="avatar"
                            src={`data:image/png;base64,${currentWxUser.head_img}`}
                          />
                        ) : (
                          currentWxUser.nickname.charAt(0)
                        )
                    ) : // 当前账号头像
                      currentWxMyAccount.head_img ? (
                        <img
                          alt="avatar"
                          src={`data:image/png;base64,${currentWxMyAccount.head_img}`}
                        />
                      ) : (
                        currentWxMyAccount.wx_nickname.charAt(0)
                      )}
                  </Avatar>
                  <Space direction={"vertical"}>
                    <Space direction="horizontal">
                      <Text>
                        {msg.role === "user"
                          ? currentWxUser.is_group
                            ? messageList[messages.length - 1 - index]
                              ? messageList[messages.length - 1 - index]
                                .nickname
                              : ""
                            : currentWxUser.nickname
                          : currentWxMyAccount.wx_nickname}
                      </Text>
                      <Text type="secondary" style={{ fontSize: "12px" }}>
                        {formatTimestampToDateTime(msg.created_at)}
                      </Text>
                      {msg.role === "user" ? (
                        <Tag color={"green"} style={{ color: "#00B42A" }}>
                          客户
                        </Tag>
                      ) : (
                        <Tag color={"orange"} style={{ color: "#FF7D00" }}>
                          销售
                        </Tag>
                      )}
                    </Space>
                    <Space direction={"vertical"}>
                      {msg.content.map((item, contentIndex) => {
                        if (
                          item.type === "text" &&
                          item.text &&
                          item.text.value
                        ) {
                          return (
                            <React.Fragment key={`${msg.id}-${contentIndex}`}>
                              {item.text.value
                                .split(/(?<=[？?!。])/)
                                .map((sentence, sentenceIndex) => (
                                  <Space
                                    key={`${msg.id}-${contentIndex}-${sentenceIndex}`}
                                    align={"center"}
                                  >
                                    <Text
                                      style={{
                                        color:
                                          msg.role === "user"
                                            ? "#000000"
                                            : "#ffffff",
                                        padding: "10px",
                                        borderRadius: "8px",
                                        backgroundColor:
                                          msg.role === "user"
                                            ? "#FFFFFF"
                                            : "#366EF4",
                                        display: "inline-block",
                                        width: "auto",
                                      }}
                                    >
                                      {sentence.replace(
                                        /[？?！!。，,；;、]+$/g,
                                        "",
                                      )}
                                    </Text>
                                  </Space>
                                ))}
                            </React.Fragment>
                          );
                        } else if (
                          item.type === "image_file" &&
                          imageUrls[item.image_file.file_id]
                        ) {
                          return (
                            <Space key={item.image_file.file_id}>
                              <img
                                src={imageUrls[item.image_file.file_id]}
                                alt="用户图片"
                                style={{ width: "250px", height: "auto" }}
                              />
                            </Space>
                          );
                        }
                        return null;
                      })}
                    </Space>
                  </Space>
                </Space>
              );
            })
        )}
      </Space>
      <Space style={{ marginTop: 20, marginLeft: "23px" }}>
        <Tooltip content="选择文件发送">
          <Upload
            action={null}
            showUploadList={false}
            // fileList={pic ? [pic] : []}
            multiple={false}
            // onChange={(_, currentFile) => handleUploadPic(_, currentFile)}
            accept=".png,.jpeg,.jpg"
          >
            <IconFolderAdd style={{ width: "20px", height: "20px" }} />
          </Upload>
        </Tooltip>
        <Input
          value={inputValue}
          onChange={(value) => setInputValue(value)}
          onPressEnter={handleSend}
          style={{
            borderRadius: "3vh",
            height: "5vh",
            width: "30vw",
            marginLeft: 10,
          }}
        />
        <Tooltip content="发送消息">
          <Button
            type="text"
            icon={
              <IconSend
                style={{ width: "20px", height: "20px", marginLeft: 10 }}
              />
            }
            onClick={handleSend}
            disabled={inputValue.trim() === ""}
          />
        </Tooltip>
      </Space>
    </Space>
  );
};

export default UserChat;
