import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Checkbox,
  Message,
  Modal,
  Space,
  Table,
} from "@arco-design/web-react";
import {
  addCaseApi,
  addCaseAssistantApi,
  delCaseAssistantByIdApi,
  deleteCaseApi,
  getAssistantCasesApi,
  getCasesApi,
  updateCaseApi,
} from "../../../api/normalApi";
import PropTypes from "prop-types";
import CaseCardModal from "./CaseCardModal";
import {deleteFileAPi} from "../../../api/openaiAPI";
const CaseMaterialModal = ({
  columns,
  tableWidth,
  assistant_id,
  caseListVisible,
  setCaseListVisible,
}) => {
  const [caseList, setCaseList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const editMode = useRef("");
  const [currentCase, setCurrentCase] = useState({});
  const [selectedCases, setSelectedCases] = useState([]); // modal中选中的案例
  const [caseListRelation, setCaseListRelation] = useState([]); // 案例总的关系表

  // 获取案例列表
  const getCases = async () => {
    try {
      const res = await getAssistantCasesApi(assistant_id);
      if (res.status !== "success") {

      }
      const initSelectedList = res.data.map((item) => item.case_id);
      setSelectedCases(initSelectedList);
      setCaseListRelation(res.data);

      const response = await getCasesApi();
      if (response.status !== "success") {

      }
      let data = response.data;

      const casesWithKey = data.map((item, index) => ({
        ...item,
        key: index + 1,
      }));
      setCaseList(casesWithKey);
    } catch (error) {
      Message.error("获取案例列表失败，请稍后重试");
    }
  };

  const extractFileName = (filePath) => {
    if (!filePath) return ""; // 如果路径为空，返回空字符串

    // 使用正则表达式提取最后一个斜杠后面的部分
    return filePath.split("/").pop();
  };

  useEffect(() => {
    getCases();
    // eslint-disable-next-line
  }, []);

  // 新增案例行
  const handleAdd = () => {
    setModalVisible(true);
    editMode.current = "add";
    setCurrentCase("");
  };

  // 编辑案例
  const handleEdit = (record) => {
    setModalVisible(true);
    editMode.current = "edit";
    setCurrentCase(record);
  };

  // 删除案例
  const handleDelete = async () => {
    if (currentCase && currentCase.id) {
      const response = await deleteCaseApi(currentCase.id);
      await deleteCaseApi(deleteFileAPi(currentCase.openai_file_id));
      if (response.status === "success") {
        Message.success("删除案例成功");
        setModalVisible(false);
        await getCases();
      } else {
        Message.error("删除案例失败");
        setModalVisible(false);
      }
    }
  };

  // 保存案例
  const handleSave = async (updatedCase) => {
    if (editMode.current === "add") {
      addCaseApi({ ...updatedCase }).then((response) => {
        if (response.status === "success") {
          // 如果有 assistant_id，则调用绑定商品和助手api
          if (assistant_id) {
            const data = [
              {
                case_id: response.data.id,
                assistant_id: assistant_id,
              },
            ];
            addCaseAssistantApi(data).then((bindResponse) => {
              if (bindResponse.status !== "success") {
                Message.error("绑定案例和助手失败");
              } else {
                getCases().then(() => {
                  Message.success("新增案例成功");
                });
              }
            });
          } else {
            getCases().then(() => {
              Message.success("新增案例成功");
            });
          }
        } else {
          Message.error("新增案例失败");
        }
      });
    } else if (editMode.current === "edit") {
      const response = await updateCaseApi(updatedCase.id, updatedCase);
      if (response.status === "success") {
        Message.success("修改案例成功");
        await getCases();
      }
    }
    setModalVisible(false);
  };

  // 定义所有可能的列
  const allColumns = [
    {
      title: "编号",
      dataIndex: "key",
      width: 70,
    },
    {
      title: "资料名称",
      dataIndex: "case_name",
    },
    {
      title: "案例描述",
      dataIndex: "description",
    },
    {
      title: "案例文件",
      dataIndex: "file_url",
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          {text ? extractFileName(text) : "暂无文件"}
        </a>
      ),
    },
    {
      title: (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button size={"small"} type="primary" onClick={handleAdd}>
            新建
          </Button>
        </div>
      ),
      dataIndex: "actions",
      align: "right", // 将这一列内容向右对齐
      render: (_, record) => (
        <div style={{ textAlign: "right" }}>
          <Button size={"small"} onClick={() => handleEdit(record)}>
            编辑
          </Button>
        </div>
      ),
    },
    {
      title: "选择",
      dataIndex: "check",
      render: (_, record) => {
        return (
          <Checkbox
            checked={selectedCases.includes(record.id)}
            onChange={() => handleCheckboxChange(record.id)}
          />
        );
      },
      width: 80,
    },
  ];

  const displayedColumns = columns
    ? allColumns.filter((col) => columns.includes(col.dataIndex))
    : allColumns;

  const handleCheckboxChange = (id) => {
    setSelectedCases((prevSelectedRows) => {
      if (prevSelectedRows.includes(id)) {
        return prevSelectedRows.filter((item) => item !== id); // 取消选择
      } else {
        return [...prevSelectedRows, id]; // 添加选择
      }
    });
  };

  const confirmCases = async () => {
    const relationIds = caseListRelation
      .filter((item) => !selectedCases.includes(item.case_id)) // 筛选 case_id 在 selectedCases 中的项
      .map((item) => item.id);

    const unRelations = selectedCases
      .filter(
        (caseId) => !caseListRelation.some((item) => item.case_id === caseId),
      )
      .map((caseId) => ({
        case_id: caseId,
        assistant_id: assistant_id, //  筛选 selecedCases中新增的
      }));

    const promises = [];
    if (relationIds.length > 0) {
      promises.push(
        delCaseAssistantByIdApi(JSON.stringify({ ids: relationIds })),
      );
    }
    if (unRelations.length > 0) {
      promises.push(addCaseAssistantApi(unRelations));
    }
    if (promises.length > 0) {
      await Promise.all(promises)
        .then(([response1, response2]) => {
          Message.success("修改绑定关系成功！");
        })
        .catch((error) => {
          console.log("修改绑定关系请求错误", error);
        });
      setCaseListVisible(false);
    } else {
      // Message.info("没有修改绑定关系")
      setCaseListVisible(false);
    }
  };

  return (
    <Modal
      title="勾选案例绑定到数字员工（允许员工在聊天中发送已选中的案例文件）"
      visible={caseListVisible}
      onOk={confirmCases}
      onCancel={() => setCaseListVisible(false)}
      style={{ width: "75vw" }}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Table
          size={"small"}
          columns={displayedColumns}
          data={caseList}
          pagination={false}
          rowKey="key"
          style={{ minWidth: tableWidth }}
        />
        <CaseCardModal
          visible={modalVisible}
          onClose={() => setModalVisible(false)}
          caseData={currentCase}
          setCaseData={setCurrentCase}
          handleSave={handleSave}
          handleDelete={handleDelete}
        />
      </Space>
    </Modal>
  );
};

CaseMaterialModal.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string),
  tableWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mode: PropTypes.oneOf(["displayModal", "normal"]),
  assistant_id: PropTypes.string,
};
// // 设置默认Props
// CaseCardModal.defaultProps = {
//     assistant_id: null,
//     mode: 'normal'
// };
export default CaseMaterialModal;
