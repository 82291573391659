import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Input,
  Upload,
  Message,
  Modal,
  Space,
  Popconfirm,
  Spin,
} from "@arco-design/web-react";
import { getCOSToken } from "../../../api/normalApi";
import COS from "cos-js-sdk-v5";
import {
  // addFileToVectorStore,
  createOpenaiVStore,
  createThread,
  generateDescriptionApi,
  generateDescriptionRuns,
  generatePic,
  getThreadMessages, listRuns,
  uploadFileAPi,
} from "../../../api/openaiAPI";
const { TextArea } = Input;

const CaseCardModal = ({
  visible,
  onClose,
  caseData,
  setCaseData,
  handleSave,
  handleDelete,
}) => {
  const [caseName, setCaseName] = useState("");
  const [caseDescription, setCaseDescription] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [currentFile, setCurrentFile] = useState(null);
  const [adviceCaseName, setAdviceCaseName] = useState("");
  const [adviceCaseDescription, setAdviceCaseDescription] = useState("");
  const [popupVisible, setPopupVisible] = useState(false); //生成的案例提示词
  const [loading, setLoading] = useState(false); //等待案例提示词生成

  // Initialize COS with updated getAuthorization using getCOSToken
  const cos = useMemo(
    () =>
      new COS({
        getAuthorization: function (options, callback) {
          getCOSToken()
            .then((response) => {
              if (response.status !== "success") {
                console.error("Failed to get COS token:", response);
                Message.error("获取COS上传凭证失败");
                return;
              }

              const data = response.data;
              if (
                !data ||
                !data.temp_key ||
                !data.temp_secret ||
                !data.bucket ||
                !data.region
              ) {
                console.error("Invalid COS token data:", data);
                Message.error("获取COS上传凭证失败，数据无效");
                return;
              }

              const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

              callback({
                TmpSecretId: data.temp_key, // 临时密钥的 tmpSecretId
                TmpSecretKey: data.temp_secret, // 临时密钥的 tmpSecretKey
                SecurityToken: data.token, // 临时密钥的 sessionToken
                StartTime: currentTime - 60, // 减少60秒作为缓冲
                ExpiredTime: data.expiration, // 临时密钥失效时间戳
                ScopeLimit: true, // 细粒度控制权限
              });
            })
            .catch((error) => {
              console.error("Error fetching COS token:", error);
              Message.error("获取COS上传凭证失败");
            });
        },
      }),
    [],
  ); // Empty dependency array to initialize COS only once

  // 当 caseData 改变时，更新组件内的状态
  useEffect(() => {
    if (caseData) {
      setCaseName(caseData.case_name || "");
      setCaseDescription(caseData.description || "");
      setFileUrl(caseData.file_url || "");
      setCurrentFile({ ...caseData, name: caseData.file_url, isNew: false });
    } else {
      setCaseName("");
      setCaseDescription("");
      setFileUrl("");
      setCurrentFile("");
    }
  }, [caseData]);

  // 将文件转换为 Base64 编码
  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  // 上传文件
  const handleFileUpload = async (curFile) => {
    setPopupVisible(true);
    setAdviceCaseDescription("");
    setAdviceCaseName("");
    setLoading(true);

    if (!curFile.originFile) {
      Message.error("未检测到上传的文件");
      return;
    }

    let nowCase = { ...caseData };
    const storedUser = localStorage.getItem("user_info");
    if (!storedUser) {
      Message.error("未检测到用户信息");
      return;
    }
    const { user_id: storedUserId } = JSON.parse(storedUser);
    nowCase.file_url = `${storedUserId}/${curFile.name}`;

    if (curFile.status === "uploading") {
      // 保存文件
      setCurrentFile({ ...curFile, isNew: true });

      // 上传文件到openai以及生成提示词
      let fileDetail;
      if (curFile) {
        fileDetail = await uploadFileAPi(curFile.originFile);
        nowCase.openai_file_id = fileDetail.id;
      }

      if (fileDetail && fileDetail.id) {
        // 判断文件是否是图片格式
        const isImage = curFile.originFile.type.startsWith("image/");
        if (isImage) {
          const base64Data = await convertFileToBase64(curFile.originFile);
          const res = await generatePic(base64Data);
          const value = JSON.parse(res.choices[0].message.content);
          setAdviceCaseName(value.标题);
          setAdviceCaseDescription(value.描述);
        } else {
          const vector_store = await createOpenaiVStore(fileDetail.id);
          nowCase.openai_vs_id = vector_store.id;
          // const add_result = await addFileToVectorStore(vector_store.id, fileDetail.id);
          // console.log("add_result", add_result);
          // const toolResources = {
          //   file_search: { vector_store_ids: [vector_store.id] }
          // };
          const thread = await createThread();
          await generateDescriptionApi(thread.id, fileDetail.id);
          await generateDescriptionRuns(thread.id);
          await listRuns(thread.id);
          const messages = await getThreadMessages(thread.id);
          if (messages.data && messages.data.length > 0) {
            const firstMessage = messages.data[0];
            const value = firstMessage.content[0]?.text?.value;
            if (value) {
              // 使用正则表达式匹配标题和描述
              const titleMatch = value.match(/"标题"\s*:\s*"(.*?)"/);
              const descriptionMatch = value.match(/"描述"\s*:\s*"(.*?)"/);
              if (titleMatch && descriptionMatch) {
                const title = titleMatch[1];
                const description = descriptionMatch[1];
                setAdviceCaseName(title);
                setAdviceCaseDescription(description);
              }
            }
          }
        }
        setLoading(false);
      }

      //上传文件到cos存储
      try {
        const res = await getCOSToken();

        if (
          !res.data ||
          !res.data.temp_key ||
          !res.data.temp_secret ||
          !res.data.bucket ||
          !res.data.region
        ) {
          Message.error("获取COS上传凭证失败，缺少必要的字段");
          return;
        }

        cos.uploadFile(
          {
            Bucket: res.data.bucket,
            Region: res.data.region,
            Key: nowCase.file_url,
            Body: curFile.originFile,
          },
          function (err, data) {
            if (err) {
              console.error("上传出错:", err);
              Message.error(`上传文件失败: ${err.message}`);
            } else {
              // console.log("上传成功:", data);
              Message.success("上传文件成功");
              setFileUrl(nowCase.file_url);
              setCaseData({ ...nowCase });
            }
          },
        );
      } catch (error) {
        console.error("获取COS Token失败:", error);
        Message.error("获取上传凭证失败");
      }
    }
  };

  // 保存或更新案例
  const handleOk = () => {
    if (!caseName) {
      Message.error("请填入案例名字");
      return;
    }
    const updatedCase = {
      ...caseData,
      case_name: caseName,
      description: caseDescription,
      file_url: fileUrl,
    };
    setCaseData((prev) => ({ ...prev, updatedCase }));
    handleSave(updatedCase);
  };

  return (
    <Modal
      style={{ width: "800px", height: "330px" }}
      visible={visible}
      onCancel={onClose}
      title="编辑案例"
      footer={
        <Space>
          <Button type="primary" onClick={handleOk}>
            确认
          </Button>
          <Button type="outline" onClick={onClose}>
            取消
          </Button>
          {caseData && caseData.id ? (
            <Button type="primary" status="danger" onClick={handleDelete}>
              删除
            </Button>
          ) : null}
        </Space>
      }
    >
      <Popconfirm
        position="rt"
        popupVisible={popupVisible}
        title="案例信息建议"
        icon={null}
        content={
          <Spin dot loading={loading}>
            <Space direction={"vertical"}>
              <span>案例名称：{adviceCaseName}</span>
              <span>案例描述：{adviceCaseDescription}</span>
            </Space>
          </Spin>
        }
        onOk={() => {
          setPopupVisible(false);
          setCaseDescription(adviceCaseDescription);
          setCaseName(adviceCaseName);
        }}
        onCancel={() => {
          setPopupVisible(false);
        }}
      >
        <Input
          placeholder="案例名称"
          value={caseName}
          onChange={(value) => setCaseName(value)}
          style={{ marginBottom: "10px" }}
        />
        <TextArea
          placeholder="案例描述"
          value={caseDescription}
          onChange={(value) => setCaseDescription(value)}
          style={{ marginBottom: "10px" }}
        />
      </Popconfirm>
      <Space align="center">
        <Upload
          showUploadList={false}
          onChange={(_, curFile) => handleFileUpload(curFile)}
        >
          <Button type="primary">
            {currentFile && currentFile.name ? "重新上传" : "上传文件"}
          </Button>
        </Upload>
        {currentFile && currentFile.name ? (
          currentFile.name.split("/").pop()
        ) : (
          <p>上传文件后会自动生成名称和描述</p>
        )}
      </Space>
    </Modal>
  );
};

export default CaseCardModal;
